// Generated by Framer (3ae0a1a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["MVGkQ70rD"];

const serializationHash = "framer-wueab"

const variantClassNames = {MVGkQ70rD: "framer-v-1apehyx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, lINK, r_serverUnAppel, width, ...props}) => { return {...props, K4091Zlcm: r_serverUnAppel ?? props.K4091Zlcm ?? "Réserver un appel", WvGtC8OYg: lINK ?? props.WvGtC8OYg} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;lINK?: string;r_serverUnAppel?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WvGtC8OYg, K4091Zlcm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "MVGkQ70rD", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={WvGtC8OYg} openInNewTab smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1apehyx", className, classNames)} framer-1cmxp7m`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"MVGkQ70rD"} ref={ref ?? ref1} style={{"--border-bottom-width": "0.5px", "--border-color": "rgba(255, 255, 255, 0.24)", "--border-left-width": "0.5px", "--border-right-width": "0.5px", "--border-style": "solid", "--border-top-width": "0.5px", backgroundColor: "var(--token-87b60bc8-4138-4bbd-949b-102d6d4894df, rgb(0, 107, 255))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7T3BlbiBTYW5zLTYwMA==", "--framer-font-family": "\"Open Sans\", \"Open Sans Placeholder\", sans-serif", "--framer-font-weight": "600", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-712a0da0-9b04-4b4c-826c-8ec32ba098c1, rgb(255, 255, 255)))"}}>Get Started</motion.p></React.Fragment>} className={"framer-10hzqfr"} data-framer-name={"Get Started"} fonts={["GF;Open Sans-600"]} layoutDependency={layoutDependency} layoutId={"u9tcQOdDm"} style={{"--extracted-r6o4lv": "var(--token-712a0da0-9b04-4b4c-826c-8ec32ba098c1, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} text={K4091Zlcm} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wueab.framer-1cmxp7m, .framer-wueab .framer-1cmxp7m { display: block; }", ".framer-wueab.framer-1apehyx { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 16px 32px 16px 32px; position: relative; text-decoration: none; width: min-content; }", ".framer-wueab .framer-10hzqfr { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-wueab.framer-1apehyx { gap: 0px; } .framer-wueab.framer-1apehyx > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-wueab.framer-1apehyx > :first-child { margin-left: 0px; } .framer-wueab.framer-1apehyx > :last-child { margin-right: 0px; } }", ".framer-wueab[data-border=\"true\"]::after, .framer-wueab [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 83
 * @framerIntrinsicWidth 204
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"WvGtC8OYg":"lINK","K4091Zlcm":"r_serverUnAppel"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramernYM9Dq76j: React.ComponentType<Props> = withCSS(Component, css, "framer-wueab") as typeof Component;
export default FramernYM9Dq76j;

FramernYM9Dq76j.displayName = "BTN";

FramernYM9Dq76j.defaultProps = {height: 83, width: 204};

addPropertyControls(FramernYM9Dq76j, {WvGtC8OYg: {title: "LINK", type: ControlType.Link}, K4091Zlcm: {defaultValue: "Réserver un appel", displayTextArea: false, title: "Réserver un appel", type: ControlType.String}} as any)

addFonts(FramernYM9Dq76j, [{explicitInter: true, fonts: [{family: "Open Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4iY1M2xLER.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})